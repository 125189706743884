<template src="./index.html"> </template>

<script>
import { mapState, mapActions } from 'vuex'
import SlideBox from '_c/SlideBox'
import SlideBoxMobile from '_c/SlideBoxMobile'
import ContactTip from '_c/ContactTip'
import { jump } from '../../util/jumpPage'
import { isPC } from '../../util/isPC'
import ProviderSection from './ProviderSection'
import PartnerSection from './PartnerSection'

export default {
  name: 'home',
  data() {
    return {
      enterTag: false, //入驻
      categorySolutionListClass: [
        'border-left-bottom border-right',
        'border-right-bottom',
        'border-right',
        'border-right',
      ],
    }
  },
  async asyncData({ store }) {
    const DISPATCH = store.dispatch
    return await Promise.all([
      DISPATCH('commonData/setCurrentPage', 'home'),
      DISPATCH('dataNews/getNewsList'),
      DISPATCH('dataPartner/getIntegratedProviderList'),
      DISPATCH('dataPartner/getHomeProviderList'),
      DISPATCH('dataModule/getHomeMainList'),
      DISPATCH('dataSolution/getHomeSolutionList'),
    ])
  },
  computed: {
    ...mapState('dataNews', {
      newsList: (s) => s.list,
    }),
    ...mapState('commonData', {
      newsTypeList: (s) => s.newsTypeList,
    }),
    ...mapState('dataPartner', {
      homeProviderList: (s) => s.homeProviderList,
      homePartnerList: (s) => s.homePartnerList,
      integratedProviderList: (s) => s.integratedProviderList,
    }),
    ...mapState('dataSuccessProject', {
      successProjectList: (s) => s.list,
    }),
    ...mapState('dataSolution', {
      homeSolutionList: (s) => s.homeSolutionList,
    }),
    ...mapState('dataModule', {
      moduleList: (s) => s.homeModuleList,
    }),
    ...mapState('dataUserInfo', {
      statusData: (s) => s.settleStatusData
    })
  },
  components: {
    SlideBox,
    SlideBoxMobile,
    ProviderSection,
    PartnerSection,
    ContactTip,
  },
  methods: {
    ...mapActions('dataUserInfo', ['getUserInfo']),
    setTouchPoint(e) {
      this.startPoint = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      }
    },
    judgeGoDetail(url) {
      if (!isPC()) {
        this.go_page(url)
      }
      // const now = {
      //   x: e.changedTouches[0].pageX,
      //   y: e.changedTouches[0].pageY
      // }
      // // 点击跳转，滑动不跳转，判断条件：起始位置距离
      // if (Math.abs(now.x - this.startPoint.x) < 5 && Math.abs(now.y - this.startPoint.y) < 5) {
      //   this.goDetail(url)
      // }
    },
    go_page(page, parmas) {
      jump(this, {
        page,
        deep: true,
        pageParmas: parmas,
      })
    },
    goConsole(){
      window.open("//console.cloud.tencent.com/marketiot")
    },
    loadUserInfo() {
      let self = this
      let userInfo = this.getUserInfo().then((re) => {
        // if (re && re.userInfo && re.userInfo.auditStatus == 11) {
        //   self.enterTag = true
        // }
      })
    },
  },
  created() {
  },
}
</script>
